











































































































































































































































































































































































.drawer--content {
  padding: 0px 20px 20px 20px;
}

.action-buttons {
  display: flex;
  align-items: center;
}
